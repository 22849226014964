import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/App.css';
import io from 'socket.io-client';

const socket = io('https://globalie11-2e8714383cbe.herokuapp.com/');

function App() {
  const [messages, setMessages] = useState([]);
  const [username, setUsername] = useState('');
  const [isUsernameSubmitted, setIsUsernameSubmitted] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [isUsernameSet, setIsUsernameSet] = useState(false);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
      setIsUsernameSet(true);
    }

    socket.on('message', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off('message');
    };
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleUsernameSubmit = () => {
    localStorage.setItem('username', username);
    setIsUsernameSubmitted(true);
  };

  const handleMessageSend = () => {
    if (!inputMessage.trim()) return;

    socket.emit('message', {
      username,
      text: inputMessage
    });

    setInputMessage('');
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-center mb-4">Chat App</h5>
              {!isUsernameSubmitted ? (
                <div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Username"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </div>
                  <button
                    className="btn btn-primary btn-block"
                    onClick={handleUsernameSubmit}
                  >
                    Set Username
                  </button>
                </div>
              ) : (
                <div>
                  <h4 className="text-center mb-4">Welcome, {username}!</h4>
                  <div className="message-container row">
                    {messages.map((msg, index) => (
                      <div key={index} className="message col-md-8 offset-md-2">
                        <strong>{msg.username}:</strong> {msg.text}
                      </div>
                    ))}
                  </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your message..."
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleMessageSend}
                      >
                        Send
                      </button>
                    
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
